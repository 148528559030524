import React, { useState, Fragment } from "react";
import "./App.css";
import Quiz from "./Quiz";

function App() {
  const [start, setStart] = useState(false);
  const [questions, setQuestions] = useState([
    {
      text: "Are you a person?",
      image: "personwaving",
    },
    {
      text: "Do you have a job that pays you?",
      image: "money",
    },
    {
      text: "Do you typically wear blue or black?",
      image: "blue",
    },
    {
      text: "Do you have a gun?",
      image: "gun",
    },
    {
      text:
        "Do you love firing tear gas at peaceful protestors who fight for an end to police brutality against Black people?",
      image: "teargas",
    },
  ]);
  const [endings, setEndings] = useState([
    {
      text:
        "You're a dog. You have no concept of human miseries such as war, capitalism, or Elon Musk. This is the best ending. You win.",
      image: "matapacos",
    },
    {
      text:
        "You're a volunteer. You provide a valuable service without even asking for pay. You're pretty great.",
      image: "volunteer",
    },
    {
      text:
        "You're a firefighter. You run into burning houses to save kittens. You're the definition of 'protect and serve'. Everyone loves you.",
      image: "firefighter",
    },
    {
      text:
        "You're a doctor, or a nurse. You work long hours, and your job is one of the most important ones humanity has. Definitely not a bastard.",
      image: "doctor",
    },
    {
      text:
        "You're a professional scuba diver. You probably shoot sharks with your spear gun and pet dolphins, or something.",
      image: "diver",
    },
    {
      text: "You're a bastard.",
      image: "bastard",
    },
  ]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [bastard, setBastard] = useState(false);
  const [end, setEnd] = useState(false);

  const buttonStyle = {
    fontSize: "1.7rem",
    padding: "0.8rem",
    color: "white",
    backgroundColor: "black",
    textDecoration: "none",
  };

  const startQuizButton = (
    <button
      key="start"
      style={buttonStyle}
      onClick={() => {
        setStart(true);
      }}
    >
      Take the quiz to find out!!!
    </button>
  );

  const yesButton = (
    <button
      key="yes"
      style={buttonStyle}
      onClick={() => {
        if (questionIndex + 1 === 5) {
          setBastard(true);
          setEnd(true);
        }
        setQuestionIndex(questionIndex + 1);
      }}
    >
      Yes
    </button>
  );

  const noButton = (
    <button
      key="no"
      style={buttonStyle}
      onClick={() => {
        setEnd(true);
      }}
    >
      No
    </button>
  );

  const restartButton = (
    <button
      key="restart"
      style={buttonStyle}
      onClick={() => {
        setEnd(false);
        setQuestionIndex(0);
        setBastard(false);
      }}
    >
      Restart
    </button>
  );

  const donateButton = (
    <a
      key="donate"
      href="https://brooklynbailfund.org/donation-form"
      style={buttonStyle}
      onClick={() => {
        setStart(true);
      }}
    >
      Donate
    </a>
  );

  if (start)
    return (
      <Fragment>
        <Quiz
          question={
            bastard
              ? "You are a bastard"
              : end
              ? "Not a bastard"
              : questions[questionIndex].text
          }
          imgSource={
            end ? endings[questionIndex].image : questions[questionIndex].image
          }
          imgAlt=""
          buttons={!end ? [yesButton, noButton] : [restartButton, donateButton]}
          text={end ? endings[questionIndex].text : null}
        />
        <p style={{ textAlign: "center", marginTop: "1.5rem" }}>
          Original idea by{" "}
          <a href="https://twitter.com/ctrlshifti">Kat Maddox</a>
        </p>
      </Fragment>
    );
  else
    return (
      <Fragment>
        <Quiz
          question="Are you a bastard?"
          imgSource="hat"
          imgAlt=""
          buttons={[startQuizButton]}
        />
        <p style={{ textAlign: "center", marginTop: "5rem" }}>
          Original idea by{" "}
          <a href="https://twitter.com/ctrlshifti">Kat Maddox</a>
        </p>
      </Fragment>
    );
}
export default App;
