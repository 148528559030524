import React from "react";

export default (props) => {
  const { question, imgSource, imgAlt, text, buttons } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "300px",
        margin: "auto",
      }}
    >
      <h1 style={{ textAlign: "center" }}>{question}</h1>
      <img
        src={require(`./assets/${imgSource}.png`)}
        alt={imgAlt}
        style={{ width: "100%", border: "2px solid black" }}
      />
      {text && <p style={{ textAlign: "center" }}>{text}</p>}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          paddingTop: "1.5rem",
          margin: "auto",
        }}
      >
        {buttons}
      </div>
    </div>
  );
};
